<template>
	<div class="container">
		<div class="title">
			<div>
				{{ $t('grantForm.title1') }}
			</div>
			<div class="sub-title">
				{{ $t('grantForm.title2') }}
			</div>
		</div>
		<div class="form">
			<form @submit.prevent v-if="state.step === 1">
				<div class="form-group">
					<input
						type="text"
						:placeholder="$t('grantForm.input1.place1')"
						v-model="state.form.projectName"
					/>
				</div>
				<div class="form-group">
					<input
						type="text"
						:placeholder="$t('grantForm.input1.place2')"
						v-model="state.form.twitter"
					/>
				</div>
				<div class="form-group">
					<input
						type="text"
						:placeholder="$t('grantForm.input1.place3')"
						v-model="state.form.website"
					/>
				</div>
				<div class="form-group input4">
					<input
						type="text"
						:placeholder="$t('grantForm.input1.place4')"
						v-model="state.form.brief"
					/>
				</div>
			</form>
			<form @submit.prevent v-if="state.step === 2">
				<div>
					<div class="label">
						{{ $t('grantForm.input2.place1') }}
						<br />
						{{ $t('grantForm.input2.place2') }}
					</div>
					<textarea
						class="form-textarea textarea2"
						maxlength="500"
						v-model="state.form.introduction"
					/>
				</div>
			</form>
			<form @submit.prevent v-if="state.step === 3">
				<div class="label">
					{{ $t('grantForm.input3.label') }}
				</div>
				<div class="label-box">
					<div class="label-item">
						<input type="radio" id="1" value="Infrastructure" v-model="state.form.category" />
						<label for="1">Infrastructure</label>
					</div>
					<div class="label-item">
						<input type="radio" id="2" value="Defi" v-model="state.form.category" />
						<label for="2">Defi</label>
					</div>
					<div class="label-item">
						<input type="radio" id="3" value="Socialfi" v-model="state.form.category" />
						<label for="3">Socialfi</label>
					</div>
					<div class="label-item">
						<input type="radio" id="4" value="Inscriptions" v-model="state.form.category" />
						<label for="4">Inscriptions</label>
					</div>
					<div class="label-item">
						<input type="radio" id="5" value="NFT" v-model="state.form.category" />
						<label for="5">NFT</label>
					</div>
					<div class="label-item">
						<input type="radio" id="6" value="MemeToken" v-model="state.form.category" />
						<label for="6">Meme Token</label>
					</div>
					<div class="label-item">
						<input type="radio" id="7" value="DAO" v-model="state.form.category" />
						<label for="7">DAO</label>
					</div>
					<div class="label-item">
						<input type="radio" id="8" value="Tools" v-model="state.form.category" />
						<label for="8">Tools</label>
					</div>
					<div class="label-item">
						<input type="radio" id="9" value="Wallet" v-model="state.form.category" />
						<label for="9">Wallet</label>
					</div>
					<div class="label-item">
						<input type="radio" id="10" value="Games" v-model="state.form.category" />
						<label for="10">Games</label>
					</div>
					<div class="label-item">
						<input type="radio" id="11" value="Metaverse" v-model="state.form.category" />
						<label for="11">Metaverse</label>
					</div>
					<div class="label-item other-item">
						<input type="radio" id="12" value="Other" v-model="state.form.category" />
						<label for="12"
							>Other :
							<input type="text" v-model="state.form.labelText" />
						</label>
					</div>
				</div>
			</form>
			<form
				class="form5"
				name="checkbox[]"
				@change="handleCheckboxChange"
				@submit.prevent
				v-if="state.step === 4"
			>
				<div class="label">
					{{ $t('grantForm.input4.label') }}
				</div>
				<div class="label-box label-box2">
					<div class="label-item">
						<input
							type="checkbox"
							id="1"
							value="Idea phase"
							name="checkbox[]"
							v-model="state.form.stage"
						/>
						<label for="1">Idea phase</label>
					</div>
					<div class="label-item">
						<input
							type="checkbox"
							id="2"
							value="In development (please note the expected launch time and pre-deployed chain)"
							name="checkbox[]"
							v-model="state.form.stage"
						/>
						<label for="2"
							>In development (please note the expected launch time and pre-deployed chain)</label
						>
					</div>
					<div class="label-item">
						<input
							type="checkbox"
							id="3"
							value="Funded (please note which institutions invested and the amount)"
							name="checkbox[]"
							v-model="state.form.stage"
						/>
						<label for="3">Funded (please note which institutions invested and the amount)</label>
					</div>
					<div class="label-item">
						<input
							type="checkbox"
							id="4"
							value="Live/Beta (if already on-chain, please state which public chain, smart contract
							address)"
							name="checkbox[]"
							v-model="state.form.stage"
						/>
						<label for="4"
							>Live/Beta (if already on-chain, please state which public chain, smart contract
							address)</label
						>
					</div>
					<div class="label-item">
						<input
							type="checkbox"
							id="5"
							value="Token launched (please note which dex/cex/launchpad..)"
							name="checkbox[]"
							v-model="state.form.stage"
						/>
						<label for="5">Token launched (please note which dex/cex/launchpad..)</label>
					</div>
					<!-- <div class="label-item">
						<input type="checkbox" id="6" value="6" name="checkbox[]" />
						<label for="6">Others (please specify)</label>
					</div> -->
					<div class="label-item other-item">
						<input type="checkbox" id="6" value="Other" v-model="state.form.stage" />
						<label for="6"
							>Other :
							<input type="text" v-model="state.form.checkText" />
						</label>
					</div>
				</div>
			</form>
			<form @submit.prevent v-if="state.step === 5">
				<div>
					<div class="label">
						{{ $t('grantForm.input5.label') }}
					</div>
					<div class="label">
						{{ $t('grantForm.input5.label2') }}
					</div>
					<textarea class="form-textarea textarea2" maxlength="500" v-model="state.form.members" />
				</div>
			</form>

			<form
				class="form5"
				name="checkbox[]"
				@change="handleCheckboxChange2"
				@submit.prevent
				v-if="state.step === 6"
			>
				<div class="label">
					{{ $t('grantForm.input6.label') }}
				</div>
				<div class="label-box label-box2 label-box3">
					<div class="label-item">
						<input
							type="checkbox"
							id="1"
							value="Fund support"
							name="checkbox[]"
							v-model="state.form.support"
						/>
						<label for="1">Fund support</label>
					</div>
					<div class="label-item">
						<input
							type="checkbox"
							id="2"
							value="Technical support"
							name="checkbox[]"
							v-model="state.form.support"
						/>
						<label for="2">Technical support</label>
					</div>
					<div class="label-item">
						<input
							type="checkbox"
							id="3"
							value="Community support"
							name="checkbox[]"
							v-model="state.form.support"
						/>
						<label for="3">Community support</label>
					</div>
					<div class="label-item">
						<input
							type="checkbox"
							id="4"
							value="User traffic support"
							name="checkbox[]"
							v-model="state.form.support"
						/>
						<label for="4">User traffic support</label>
					</div>
					<div class="label-item">
						<input
							type="checkbox"
							id="5"
							value="Marketing & Branding support"
							name="checkbox[]"
							v-model="state.form.support"
						/>
						<label for="5">Marketing & Branding support</label>
					</div>
					<div class="label-item">
						<input
							type="checkbox"
							id="6"
							value="IDO/DEX/CEX listing support"
							name="checkbox[]"
							v-model="state.form.support"
						/>
						<label for="6">IDO/DEX/CEX listing support</label>
					</div>
					<div class="label-item other-item">
						<input
							type="checkbox"
							id="7"
							value="Other"
							name="checkbox[]"
							v-model="state.form.support"
						/>
						<label for="7"
							>Other :
							<input type="text" v-model="state.form.checkText2" />
						</label>
					</div>
				</div>
			</form>
			<form class="form7" @submit.prevent v-if="state.step === 7">
				<div class="m-b-50 label">
					{{ $t('grantForm.input7.label') }}
				</div>
				<div class="form-group">
					<input
						type="text"
						:placeholder="$t('grantForm.input7.place1')"
						v-model="state.form.contractPerson"
					/>
				</div>
				<div class="form-group">
					<input
						type="email"
						:placeholder="$t('grantForm.input7.place2')"
						v-model="state.form.contractEmail"
					/>
				</div>
				<div class="form-group">
					<input
						type="text"
						:placeholder="$t('grantForm.input7.place3')"
						v-model="state.form.contractTg"
					/>
				</div>
				<div class="form-group input4">
					<input
						type="text"
						:placeholder="$t('grantForm.input7.place4')"
						v-model="state.form.walletAddress"
					/>
				</div>
			</form>
			<form class="form7" @submit.prevent v-if="state.step === 8">
				<div class="m-b-50 label">{{ $t('grantForm.input8.label') }}</div>
				<div class="form-group">
					<input
						type="text"
						:placeholder="$t('grantForm.input7.place1')"
						v-model="state.form.recommendPerson"
					/>
				</div>
				<div class="form-group">
					<input
						type="email"
						:placeholder="$t('grantForm.input7.place2')"
						v-model="state.form.recommendEmail"
					/>
				</div>
				<div class="form-group">
					<input
						type="text"
						:placeholder="$t('grantForm.input7.place3')"
						v-model="state.form.recommendTg"
					/>
				</div>
				<div class="form-group input4">
					<input
						type="text"
						:placeholder="$t('grantForm.input7.place4')"
						v-model="state.form.recommendWallet"
					/>
				</div>
			</form>
			<div class="btn-group">
				<button class="btn" @click="back">{{ $t('grantForm.back') }}</button>
				<button class="btn btn-primary" @click="next">{{ $t('grantForm.next') }}</button>
			</div>
			<div class="step-process">
				<div
					class="point"
					:style="{
						marginLeft: `${
							stepProcessMap[state.step] === 100 ? 98.6 : stepProcessMap[state.step]
						}%`,
					}"
				>
					<span class="text">{{ stepProcessMap[state.step] }}%</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { reactive, defineEmits } from 'vue';
import { submitGrantData } from '@/api/index';

const stepProcessMap = {
	1: 0,
	2: 15,
	3: 30,
	4: 45,
	5: 60,
	6: 75,
	7: 90,
	8: 100,
};

const state = reactive({
	step: 1,
	form: {
		projectName: '',
		twitter: '',
		website: '',
		brief: '',
		introduction: '',
		category: '',
		labelText: '',
		stage: [],
		checkText: '',
		members: '',
		support: [],
		checkText2: '',
		contractPerson: '', //联系人
		contractEmail: '', //联系人email
		contractTg: '', //联系人tg
		walletAddress: '', //钱包地址
		recommendPerson: '', //联系人
		recommendEmail: '', //联系人email
		recommendTg: '', //联系人tg
		recommendWallet: '', //钱包地址
	},
});

const emit = defineEmits(['back']);

const handleCheckboxChange = (e) => {
	// let value = e.target.defaultValue;
	// if (state.form.stage.indexOf(value) == 'Other') {
	// 	state.form.stage.push(value);
	// } else {
	// 	let index = state.form.stage.indexOf(value); // 在数组中查找要删除的元素的索引
	// 	state.form.stage.splice(index, 1);
	// }
	console.log('stage', state.form.stage);
};

const handleCheckboxChange2 = (e) => {
	// let value = e.target.defaultValue;
	// if (state.form.support.indexOf(value) == -1) {
	// 	state.form.support.push(value);
	// 	console.log('add', state.form.support);
	// } else {
	// 	let index = state.form.support.indexOf(value); // 在数组中查找要删除的元素的索引
	// 	state.form.support.splice(index, 1);
	// 	console.log('delete', state.form.support);
	// }
	console.log('support', state.form.support);
};

const back = () => {
	if (state.step === 1) {
		return emit('back2content');
	}
	state.step--;
};

const next = () => {
	// if (state.step === 4) {
	// }
	if (state.step === 8) {
		let data = {
			...state.form,
		};
		console.log(state.form, 'form');
		console.log('data', data);
		if (state.form.category == 'Other') {
			data.category == state.form.labelText;
		}
		if (state.form.stage.indexOf('Other') != -1) {
			let index = state.form.stage.indexOf('Other'); // 在数组中查找要删除的元素的索引
			data.stage.splice(index, 1, state.form.checkText);
		}
		if (state.form.support.indexOf('Other') != -1) {
			let index = state.form.support.indexOf('Other'); // 在数组中查找要删除的元素的索引
			data.support.splice(index, 1, state.form.checkText2);
		}
		data.stage = JSON.stringify(data.stage);
		data.support = JSON.stringify(data.support);
		submitGrantData(data).then((res) => {
			console.log(res);
		});
		return emit('next2finish');
	}
	state.step++;
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
	.container {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0px 0 188px;
		background-color: rgba(0, 0, 0, 1);
		color: white;
		// overflow: hidden;
		padding: 0 7.5px;
		position: relative;

		.title {
			width: 100%;
			height: 298px;
			background: url('~@/assets/h5grant/banner.png');
			background-size: 100% 298px;
			text-align: center;
			font-size: 36px;
			font-weight: 700;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 68px;

			.sub-title {
				margin: 15px 0 0;
				width: 270px;
				font-weight: 400;
				font-size: 16px;
			}
		}

		.form {
			width: 100%;
			min-height: 298px;
			overflow: hidden;
			background-image: url('~@/assets/h5portfolio/form_bg.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100% 100%;
			padding: 46px 29px 0;
			border-radius: 4px;

			.btn-group {
				width: calc(100% - 74px);
				position: absolute;
				display: flex;
				justify-content: space-between;
				// padding: 0 0 0 0;
				left: 37px;
				right: 37px;
				bottom: -72px;

				.btn {
					font-size: 10px;
					width: 108px;
					height: 34px;
					line-height: 14px;
					border: none;
					padding: 10px 40px;
					color: black;
					background-color: #fff;
					cursor: pointer;
					font-weight: bold;
					// color: #333333;
					border-radius: 4px;

					&:active {
						background-color: #b4b4b4;
					}
				}
				.btn-primary {
					color: white;
					background-color: #1f78d3;

					&:active {
						background: rgb(18, 78, 138);
					}
				}
			}
			.step-process {
				width: 100%;
				height: 1px;
				background: linear-gradient(90deg, #fdaa33 0%, #1f78d3 100%);
				border-radius: 100px;
				position: relative;
				display: flex;
				align-items: center;
				margin: 32px 0 20px;

				.point {
					width: 5.36px;
					height: 5.36px;
					background-color: #ffffff;
					box-shadow: 0px 4px 4px 0px #fdaa3340 inset;
					border-radius: 100px;
					position: relative;

					.text {
						position: absolute;
						top: 100%;
						color: #fff;
						font-size: 8.52px;
						font-weight: 500;
						text-align: left;
						margin-left: -5px;
					}
				}
			}
			.form-group {
				background-color: #d9d9d9;
				// padding: 24px;
				height: 24px;
				border-radius: 2px;
				margin-bottom: 26px;
				position: relative;

				input {
					box-sizing: border-box;
					background-color: #d9d9d9;
					border: none;
					width: 100%;
					height: 24px;
					font-weight: bold;
					font-size: 8px;

					&:placeholder {
						font-size: 8px;
						color: #676767;
					}
				}
			}
			.label-box {
				margin-top: 24px;
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				// justify-content: space-between;
			}
			.label-item:nth-child(3n) {
				margin-right: 0;
			}
			.label-box2 {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;

				.label-item {
					width: auto;
					height: auto;
					// line-height: 11px;
					label {
						display: flex;
						align-items: center;
						flex-wrap: nowrap;
					}
					input[type='checkbox'] + label::before {
						min-width: 13.5px;
						min-height: 13.5px;
					}
				}
			}
			.label-item:last-child {
				margin-bottom: 0;
			}
			.label-item {
				cursor: pointer;
				width: 94px;
				height: 24.84px;
				background: rgba($color: #ffffff, $alpha: 0);
				padding: 6.9px 0 6.9px 8px;
				border: 1px solid rgba(255, 255, 255, 1);
				border-radius: 12.42px;
				display: inline-flex;
				align-items: center;
				margin-bottom: 14px;
				margin-right: 10px;
				input[type='radio'] + label::before {
					content: '\a0'; /*不换行空格*/
					display: inline-block;
					vertical-align: middle;
					font-size: 9px;
					font-weight: 500;
					width: 11px;
					height: 11px;
					margin-right: 6px;
					border-radius: 50%;
					border: 1px solid #ffffff;
					box-sizing: border-box;
					line-height: 9px;
					margin-top: -2px;
				}
				input[type='radio']:checked + label::before {
					background-color: #ffffff;
					background-clip: content-box;
				}
				input[type='radio'] {
					position: absolute;
					clip: rect(0, 0, 0, 0);
				}

				input[type='checkbox'] + label::before {
					content: '\a0'; /*不换行空格*/
					display: inline-block;
					vertical-align: middle;
					// font-size: 9px;
					font-weight: 500;
					width: 11px;
					height: 11px;
					margin-right: 6px;
					border-radius: 50%;
					border: 1px solid #ffffff;
					box-sizing: border-box;
					// line-height: 9px;
					margin-top: -2px;
				}
				input[type='checkbox']:checked + label::before {
					background-color: #ffffff;
					background-clip: content-box;
				}
				input[type='checkbox'] {
					position: absolute;
					clip: rect(0, 0, 0, 0);
				}
				label {
					margin: 0;
					font-size: 9px;
					box-sizing: border-box;

					input {
						background-color: rgba($color: #ffffff, $alpha: 0);
						display: inline-block;
						border: none;
						width: 210px;
						font-weight: bold;
						font-size: 9px;
						color: #ffffff;

						&:placeholder {
							font-size: 9px;
							color: #ffffff;
						}
					}
				}
			}
			.label-box3 {
				.label-item {
					// width: 302px;
				}
			}
			.other-item {
				width: 100%;
				// padding: 6.9px 0 6.9px 8px;
			}
			.input4 {
				margin-bottom: 42px;
			}
			.form7 {
				padding-top: 26px;
			}
			.form5 {
				// padding-top: 57px;

				.other-item {
					width: 308px;
				}
			}
			.label {
				font-size: 12px;
				font-weight: 500;
				margin-bottom: 10px;
			}

			.form-textarea {
				outline: none;
				box-sizing: border-box;
				border: none;
				resize: none;
				display: block;
				width: 100%;
				height: 141px;
				padding: 10px;
				border-radius: 2px;
				background: #272727;
				font-size: 8px;
				margin-top: 3px;
				color: #ffffff;
			}
			.textarea2 {
				height: 141px;
			}
			.textarea4 {
				height: 42px;
			}
		}
	}
}
@media screen and (min-width: 768px) {
	.container {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 82px 0 188px;
		background-color: rgba(21, 21, 21, 1);
		color: white;
		overflow: hidden;

		.title {
			text-align: center;
			font-size: 64px;
			font-weight: 500;
			display: flex;
			flex-direction: column;
			align-items: center;

			.sub-title {
				margin: 14px 0 39px;
				width: 874px;
				font-weight: 400;
				font-size: 20px;
			}
		}

		.form {
			width: 1253px;
			height: 816px;
			overflow: hidden;
			background-image: url('~@/assets/portfolio/form_bg.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			padding: 0 120px 0;
			border-radius: 20px;

			form {
				padding-top: 108px;
				// height: 816px;
			}
			.form-group {
				background-color: #d9d9d9;
				padding: 24px;
				border-radius: 10px;
				margin-bottom: 62px;
				position: relative;

				input {
					background-color: #d9d9d9;
					border: none;
					width: 100%;
					font-weight: bold;
					font-size: 16px;

					&:placeholder {
						font-size: 16px;
						color: #676767;
					}
				}
			}
			.label-box {
				margin-top: 40px;
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				// justify-content: space-between;
			}
			.label-item:nth-child(5n) {
				margin-right: 0;
			}
			.label-box2 {
				.label-item {
					width: auto;
				}
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
			}
			.label-item {
				cursor: pointer;
				width: 184px;
				height: 45px;
				background: rgba($color: #ffffff, $alpha: 0);
				padding: 12.5px 0 12.5px 19px;
				border: 1px solid rgba(255, 255, 255, 1);
				border-radius: 22.5px;
				display: inline-flex;
				align-items: center;
				margin-bottom: 30px;
				margin-right: 20px;
				input[type='radio'] + label::before {
					content: '\a0'; /*不换行空格*/
					display: inline-block;
					vertical-align: middle;
					font-size: 16px;
					font-weight: 500;
					width: 16px;
					height: 16px;
					margin-right: 17px;
					border-radius: 50%;
					border: 2px solid #ffffff;
					box-sizing: border-box;
					line-height: 20px;
					margin-top: -2px;
				}
				input[type='radio']:checked + label::before {
					background-color: #ffffff;
					background-clip: content-box;
				}
				input[type='radio'] {
					position: absolute;
					clip: rect(0, 0, 0, 0);
				}
				label {
					margin: 0;
					height: 20px;
					box-sizing: border-box;

					input {
						background-color: rgba($color: #ffffff, $alpha: 0);
						border: none;
						width: 500px;
						font-weight: bold;
						font-size: 16px;
						color: #ffffff;

						&:placeholder {
							font-size: 16px;
							color: #ffffff;
						}
					}
				}
			}
			.label-box3 {
				.label-item {
					width: 330px;
				}
			}
			.other-item {
				width: 638px;
			}
			.input4 {
				margin-bottom: 42px;
			}
			.form7 {
				padding-top: 26px;
			}
			.form5 {
				padding-top: 57px;

				.other-item {
					width: 1038px;
				}
			}
			label {
				font-size: 16px;
				font-weight: bold;
				margin-bottom: 10px;
			}

			.form-textarea {
				background-color: #d9d9d9;
				border: none;
				resize: none;
				display: block;
				width: 100%;
				height: 200px;
				padding: 20px;
				border-radius: 10px;
				margin-bottom: 20px;
				font-size: 16px;
				margin-top: 10px;
			}
			.textarea2 {
				height: 316px;
			}
			.textarea4 {
				height: 42px;
			}
		}
	}

	.m-b-50 {
		margin-bottom: 50px;
	}

	.btn-group {
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
		padding: 0 20px;
	}

	.btn {
		border: none;
		padding: 16px 20px;
		color: black;
		background-color: #fff;
		cursor: pointer;
		font-weight: bold;

		&:active {
			background-color: #b4b4b4;
		}
	}

	.btn-primary {
		color: white;
		background-color: #1f78d3;

		&:active {
			background: rgb(18, 78, 138);
		}
	}

	.step-process {
		width: 100%;
		height: 10px;
		background: linear-gradient(90deg, #fdaa33 0%, #1f78d3 100%);
		border-radius: 100px;
		position: relative;
		display: flex;
		align-items: center;
		margin-top: 40px;
		margin-bottom: 160px;

		.point {
			width: 16px;
			height: 16px;
			background-color: #ffffff;
			box-shadow: 0px 4px 4px 0px #fdaa3340 inset;
			border-radius: 100px;
			position: relative;

			.text {
				position: absolute;
				top: 100%;
				color: #fff;
				font-size: 16px;
				font-weight: bold;
				text-align: center;
			}
		}
	}
}
</style>
