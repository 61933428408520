<template>
	<div class="grant">
		<div class="banner">
			<div class="one">
				{{ $t('grant.one') }}
			</div>
			<div class="two" @click="emit('submission')">
				{{ $t('grant.two') }}
			</div>
		</div>
		<div class="content">
			<div class="title1">
				<span class="bolder">{{ $t('grant.title1.bolder') }}</span> {{ $t('grant.title1.content') }}
			</div>
			<div class="title2">
				{{ $t('grant.title2.font1') }}
			</div>
			<div class="title2">
				{{ $t('grant.title2.font2') }}
			</div>
		</div>
		<div class="body">
			<div class="left">
				<div class="title" ref="a">
					{{ $t('grant.Goal.title') }}
				</div>
				<ul>
					<li>
						{{ $t('grant.Goal.content1') }}
					</li>
					<li>
						{{ $t('grant.Goal.content2') }}
					</li>
					<li>
						{{ $t('grant.Goal.content3') }}
					</li>
				</ul>
				<div class="title" ref="b">
					{{ $t('grant.Grant.title') }}
				</div>
				<ul>
					<li>
						{{ $t('grant.Grant.content1') }}
					</li>
					<li>
						{{ $t('grant.Grant.content2') }}
					</li>
					<li>
						{{ $t('grant.Grant.content3') }}
					</li>
				</ul>
				<div class="title" ref="c">
					{{ $t('grant.Qualification.title') }}
				</div>
				<ul>
					<li>
						{{ $t('grant.Qualification.content1') }}
					</li>
					<li>
						{{ $t('grant.Qualification.content2') }}
					</li>
					<li>
						{{ $t('grant.Qualification.content3') }}
					</li>
					<li>
						{{ $t('grant.Qualification.content4') }}
					</li>
				</ul>
				<div class="title" ref="d">
					{{ $t('grant.Process.title') }}
				</div>
				<ul>
					<li>
						{{ $t('grant.Process.content1') }}
					</li>
					<li>
						{{ $t('grant.Process.content2') }}
					</li>
					<li>
						{{ $t('grant.Process.content3') }}
					</li>
					<li>
						{{ $t('grant.Process.content4') }}
					</li>
				</ul>
				<div class="title" ref="e">
					{{ $t('grant.Funding.title') }}
				</div>
				<ul>
					<li>
						{{ $t('grant.Funding.content1') }}
					</li>
					<li>
						{{ $t('grant.Funding.content2') }}
					</li>
					<li>
						{{ $t('grant.Funding.content3') }}
					</li>
				</ul>
				<div class="title" ref="f">Community Participation and Support</div>
				<ul>
					<li>M3 Community</li>
					<li>Seminar and workshop</li>
					<li>Display</li>
					<li>
						Moreover, we will follow up on the progress of winning projects regularly to ensure
						smooth subsequent development.
					</li>
				</ul>
			</div>
			<div class="navright">
				<div
					class="item"
					:class="{ active: active == 'a' ? true : false }"
					@click="scrollToAnchor('a')"
				>					
					{{ $t('grant.Goal.title') }}
				</div>
				<div
					class="item"
					:class="{ active: active == 'b' ? true : false }"
					@click="scrollToAnchor('b')"
				>
					{{ $t('grant.Grant.title') }}
				</div>
				<div
					class="item"
					:class="{ active: active == 'c' ? true : false }"
					@click="scrollToAnchor('c')"
				>
					{{ $t('grant.Qualification.title') }}
				</div>
				<div
					class="item"
					:class="{ active: active == 'd' ? true : false }"
					@click="scrollToAnchor('d')"
				>
					{{ $t('grant.Process.title') }}
				</div>
				<div
					class="item"
					:class="{ active: active == 'e' ? true : false }"
					@click="scrollToAnchor('e')"
				>
					{{ $t('grant.Funding.title') }}
				</div>
				<div
					class="item"
					:class="{ active: active == 'f' ? true : false }"
					@click="scrollToAnchor('f')"
				>
					{{ $t('grant.Community.title') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
const emit = defineEmits(['submission']);

const a = ref(null);
const b = ref(null);
const c = ref(null);
const d = ref(null);
const e = ref(null);
const f = ref(null);
const active = ref('a');
const scrollToAnchor = (ref) => {
	active.value = ref;
	if (ref == 'a') {
		a.value.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
	} else if (ref == 'b') {
		b.value.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
	} else if (ref == 'c') {
		c.value.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
	} else if (ref == 'd') {
		d.value.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
	} else if (ref == 'e') {
		e.value.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
	} else if (ref == 'f') {
		f.value.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
	}
};
const go = (url) => {
	window.open(url, '_blank');
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 768px) {
	.grant {
		width: 100%;
		background: rgba(0, 0, 0, 1);
		color: #ffffff;
		.banner {
			margin-top: -50px;
			width: 100%;
			height: 298px;
			background: url('~@/assets/h5grant/banner.png');
			background-size: 100% 298px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.one {
				font-size: 36px;
				font-weight: 700;
				margin: 118px 0 44px;
				width: 200px;
				text-align: center;
			}
			.two {
				font-size: 20px;
				font-weight: 500;
				width: 318px;
				height: 48px;
				text-align: center;
				line-height: 48px;
				cursor: pointer;
				border: 1px solid rgba(255, 255, 255, 0.6);
				border-top: 0px;
				border-bottom: 0px;
				background: rgba(217, 217, 217, 0.3);
				border-radius: 60px;
			}
			.two:hover {
				border: 2px solid rgba(255, 255, 255, 0.6);
				border-top: 0px;
				border-bottom: 0px;
				background: linear-gradient(90deg, #2d7ccb 0%, #fbaa35 100%);
			}
		}
		.content {
			width: 100%;
			padding: 44px 30px 0;
			font-weight: 400;
			line-height: normal;
			font-style: normal;
			.title1 {
				width: 100%;
				color: #f5f5f5;
				font-size: 14px;
				margin-bottom: 35px;
				line-height: 26px;
				.bolder {
					font-weight: 900;
				}
			}
			.title2 {
				width: 100%;
				color: #1f78d3;
				font-size: 18px;
				font-weight: 700;
				margin-bottom: 2px;
				margin-bottom: 35px;
			}
		}

		.body {
			width: 100%;
			padding: 10px 29px 0;
			// display: flex;
			.left {
				width: 100%;
				.title {
					width: 100%;
					color: #1f78d3;
					font-size: 14px;
					font-weight: 700;
					margin-bottom: 22px;
					margin-top: 43px;
				}
				.title:nth-child(1) {
					margin-top: 0;
				}
				ul {
					width: 100%;
					padding-left: 0px;
					text-transform: capitalize;
					li {
						width: 100%;
						font-size: 14px;
						font-weight: 400;
					}
					li:last-child {
						margin-bottom: 0 !important;
					}
				}
			}
			.navright {
				display: none;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.grant {
		width: 100%;
		background: rgba(0, 0, 0, 1);
		color: #ffffff;

		.banner {
			width: 100%;
			height: 460px;
			background: url('~@/assets/grant/banner.png');
			background-size: 1920px 460px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.one {
				font-size: 64px;
				font-weight: 500;
				margin: 112px 0 35px;
			}
			.two {
				font-size: 20px;
				font-weight: 500;
				width: 250px;
				height: 51px;
				text-align: center;
				line-height: 51px;
				cursor: pointer;
				border: 2px solid rgba(255, 255, 255, 0.6);
				border-top: 0px;
				border-bottom: 0px;
				background: rgba(217, 217, 217, 0.3);
				border-radius: 20px;
			}
			.two:hover {
				border: 2px solid rgba(255, 255, 255, 0.6);
				border-top: 0px;
				border-bottom: 0px;
				background: linear-gradient(90deg, #2d7ccb 0%, #fbaa35 100%);
			}
		}
		.content {
			width: 100%;
			padding: -0 0 11px 315px;
			margin-top: -96px;
			font-weight: 400;
			line-height: normal;
			font-style: normal;
			.title1 {
				width: 1080px;
				color: #f5f5f5;
				font-size: 20px;
				margin-bottom: 35px;
				.bolder {
					font-weight: 900;
				}
			}
			.title2 {
				width: 1080px;
				color: #1f78d3;
				font-size: 32px;
				font-weight: 700;
				margin-bottom: 2px;
			}
		}
		.body {
			width: 100%;
			padding: 0 0 100px 315px;
			display: flex;
			justify-content: space-between;
			position: relative;
			.left {
				width: 1080px;
				font-style: normal;
				line-height: normal;
				.title {
					color: #1f78d3;
					font-size: 24px;
					font-weight: 700;
					margin-bottom: 22px;
					margin-top: 43px;
				}
				.title:nth-child(1) {
					margin-top: 0;
				}
				ul {
					padding-left: 16px;
					font-size: 18px;
					font-weight: 400;
					text-transform: capitalize;
				}
			}
			.navright {
				position: sticky;
				top: 180px;
				right: 171px;
				width: 350px;
				height: 330px;
				border-left: 1px solid rgba(50, 50, 50, 1);
				.active {
					border-left: 4px solid #1b72cb;
					color: #f5f5f5;
				}
				.item {
					width: 100%;
					height: 80px;
					padding: 27.5px 20px;
					color: #505050;
				}
				.item:hover {
					border-left: 4px solid #1b72cb;
					color: #f5f5f5;
				}
			}
		}
	}
}
</style>
